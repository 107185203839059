import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

export const i18n = i18next
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      // order and from where user language should be detected
      order: ["localStorage", "navigator"],

      // keys or params to lookup language from
      lookupLocalStorage: "i18nextLng",
      // cache user language on
      caches: ["localStorage"],
      excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)
    },
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          "First Name": "First Name",
          "Last Name": "Last Name",
          Email: "Email",
          "Phone Number": "Phone Number",
          "Zip Code": "Zip Code",
          "5 digit code": "5 digit code",
          "Exit Show": "Exit Show",
          "Find Your Event": "Find Your Event",
          "Search below or use your current location":
            "Search below or use your current location",
          "Finding Your Show": "Finding Your Show",
          "Use My Location": "Use My Location",
          "Please enable location services to find nearby shows.":
            "Please enable location services to find nearby shows.",
          "Hey Artists": "Hey Artists",
          "Engage and reward your fans!": "Engage and reward your fans!",
          "Give your biggest fans the chance to interact with you in real time":
            "Give your biggest fans the chance to interact with you in real time",
          "Learn More!": "Learn More!",
          "Get Started": "Get Started",
          "We couldn't seem to find your location, please try again.":
            "We couldn't seem to find your location, please try again.",
          "We've encountered a server issue, please try again.":
            "We've encountered a server issue, please try again.",
          "Use of this site constitutes acceptance of our":
            "Use of this site constitutes acceptance of our",
          "User Agreement and Privacy Policy and Cookie Statement.":
            "User Agreement and Privacy Policy and Cookie Statement.",
          "No purchase necessary. Void where prohibited. By clicking":
            "No purchase necessary. Void where prohibited. By clicking",
          "you acknowledge and accept the": "you acknowledge and accept the",
          "Official Rules": "Official Rules",
          Vote: "Vote",
          "Check In": "Check In",
          "Search by artist name or venue...":
            "Search by artist name or venue...",
          Submitting: "Submitting",
          "No matches found...": "No matches found...",
          "You have donated": "You have donated",
        },
      },
      es: {
        translation: {
          "First Name": "Nombre",
          "Last Name": "Apellido",
          Email: "Correo Electrónico",
          "Phone Number": "Número de teléfono",
          "Zip Code": "Código Postal",
          "5 digit code": "Código de 5 dígitos",
          "Exit Show": "Salir del Show",
          "Find Your Event": "Encuentra tu Evento",
          "Search below or use your current location":
            "Busca a continuación o usa tu ubicación actual",
          "Finding Your Show": "Encontrando tu Show",
          "Use My Location": "Usar mi Ubicación",
          "Please enable location services to find nearby shows.":
            "Por favor habilita los servicios de ubicación para encontrar shows cercanos.",
          "Hey Artists": "Hola Artistas",
          "Engage and reward your fans!": "Conecta y premia a tus fans",
          "Give your biggest fans the chance to interact with you in real time":
            "Brinda a tus más grandes fans la posibilidad de interactuar contigo en vivo",
          "Learn More!": "¡Aprende más!",
          "Get Started": "Comenzar",
          "We couldn't seem to find your location, please try again.":
            "No fue posible obtener tu ubicación, por favor intenta de nuevo.",
          "We've encountered a server issue, please try again.":
            "Ocurrió un error en el servidor, por favor intenta de nuevo.",
          "Use of this site constitutes acceptance of our":
            "El uso de este sitio implica aceptar nuestro",
          "User Agreement and Privacy Policy and Cookie Statement.":
            "Acuerdo de usuario, Política de privacidad y Declaración de cookies.",
          "No purchase necessary. Void where prohibited. By clicking":
            "No se requiere una compra. No es válido en lugares donde este prohibido. Al hacer clic en",
          "you acknowledge and accept the": "reconoce y acepta las",
          "Official Rules": "Reglas oficiales",
          Vote: "Votar",
          "Check In": "Registrarse",
          "Search by artist name or venue...": "Buscar por artista o lugar...",
          Submitting: "Submitting",
          "No matches found...": "No matches found...",
          "You have donated": "You have donated",
        },
      },
      pt: {
        translation: {
          "First Name": "Primeiro nome",
          "Last Name": "Sobrenome",
          Email: "E-mail",
          "Phone Number": "Número de telefone",
          "Zip Code": "CEP",
          "5 digit code": "código de 5 dígitos",
          "Exit Show": "Sair da Mostra",
          "Find Your Event": "Encontre seu evento",
          "Search below or use your current location":
            "Pesquise abaixo ou use sua localização atual",
          "Finding Your Show": "Encontrando seu programa",
          "Use My Location": "Use minha localização",
          "Please enable location services to find nearby shows.":
            "Ative os serviços de localização para encontrar shows próximos.",
          "Hey Artists": "Oi artistas",
          "Engage and reward your fans!": "Envolva e recompense seus fãs!",
          "Give your biggest fans the chance to interact with you in real time":
            "Dê aos seus maiores fãs a chance de interagir com você em tempo real",
          "Learn More!": "Saber mais!",
          "Get Started": "Iniciar",
          "We couldn't seem to find your location, please try again.":
            "Não foi possível encontrar sua localização. Tente novamente.",
          "We've encountered a server issue, please try again.":
            "Encontramos um problema no servidor, tente novamente.",
          "Use of this site constitutes acceptance of our":
            "O uso deste site constitui a aceitação de nossos",
          "User Agreement and Privacy Policy and Cookie Statement.":
            "Contrato do usuário e política de privacidade e declaração de cookies.",
          "No purchase necessary. Void where prohibited. By clicking":
            "Nenhuma compra necessária. Inválido onde proibido. Clicando",
          "you acknowledge and accept the": "você reconhece e aceita o",
          "Official Rules": "Regras oficiais",
          Vote: "Voto",
          "Check In": "Check-in",
          "Search by artist name or venue...":
            "Pesquise pelo nome do artista ou local...",
          Submitting: "Enviando",
          "No matches found...": "Nenhuma equivalência encontrada...",
          "You have donated": "você doou",
        },
      },
    },
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
