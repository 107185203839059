import { useContext } from "react";
import { SpotifyContext } from "providers";

export const useSpotifyContext = () => {
  const context = useContext(SpotifyContext);
  if (!context) {
    throw new Error("useSpotifyContext must be used within a Spotify Provider");
  }
  return context;
};
