import { DefaultTheme } from "styled-components";

export const getBorderColor = (
  theme: DefaultTheme,
  isSelected?: boolean,
  disabled?: boolean
) => {
  if (isSelected && disabled) {
    return theme.colors.black40;
  }

  if (disabled) {
    return theme.colors.gray4;
  }

  if (isSelected) {
    return theme.colors.navy;
  }

  return theme.colors.black40;
};

export const getBgColor = (
  theme: DefaultTheme,
  isSelected?: boolean,
  disabled?: boolean
) => {
  if (isSelected && disabled) {
    return theme.colors.black40;
  }

  if (isSelected) {
    return theme.colors.navy;
  }

  return "transparent";
};
