import { FirebaseProvider } from './providers';
import { Theme } from "./melodies-source/Theme";
import { BrowserRouter as Router } from 'react-router-dom';
import { InternalRoutes } from 'Routes';
import {
  HistoryProvider,
  LocationProvider,
  UserProvider,
} from "./Components";
import { SpotifyProvider } from 'providers';
import { HelmetProvider } from "react-helmet-async";

export const App = () => {

  return (
    <FirebaseProvider>
      <Router>
        <HistoryProvider>
          <Theme>
            <UserProvider>
              <HelmetProvider>
                <LocationProvider>
                  <SpotifyProvider>
                    <InternalRoutes />
                  </SpotifyProvider>
                </LocationProvider>
              </HelmetProvider>
            </UserProvider>
          </Theme>
        </HistoryProvider>
      </Router>
    </FirebaseProvider>
  )
}