import { ReactNode } from "react";
import styled from 'styled-components';
import mainImageSrc from "./MainImage.jpg";
import { SvgTicket } from "melodies-source/Svgs/Ticket";
import { SetLiveKnockoutLogo } from "Images/Logos";
import { Body2, H2, Subtitle2 } from "melodies-source/Text";

export const DefaultLayout = ({
    children = null, 
}:{ children: ReactNode }) => {

  return (
    <Container>
        <Wrapper>
            <Content>
              <Head>
                <Logo />
              </Head>
                <MaskedContainer>
                    <TicketContainer>
                        <TicketIcon />
                    </TicketContainer>
                    <GradientColor />
                    <SmallGradient />
                    <LargeGradient />
                    <MainImage src={mainImageSrc} />
                </MaskedContainer>
                {children}
            </Content>
        </Wrapper>
    </Container>
  );

};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background: #000000;
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 616px;
  flex-grow: 1;
  padding: 8px;
  position: relative;
  ${({ theme }) => theme.mediaQueries.desktop} {
    height: 658px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  padding: 56px 32px;
  position: relative;
  flex: 1 1 100%;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  z-index: 3;
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  ${H2}, ${Body2}, ${Subtitle2} {
    color: #ffffff;
    width: 100%;
    text-align: center;
  }
  ${H2} {
    font-weight: 700;
    text-transform: uppercase;
  }
  ${Body2} {
    margin-bottom: 16px;
    font-weight: 500;
  }
  ${Subtitle2} {
    opacity: 0.7;
    font-weight: 500;
    margin-top: 8px;
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    ${H2} {
      font-size: 34px;
      line-height: 50px;
    }
    ${Body2} {
      font-size: 15px;
      line-height: 22px;
    }
    ${Subtitle2} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 0;
  top: 8px;
  left: 8px;
  right: 8px;
  z-index: 10;
  ${({ theme }) => theme.mediaQueries.desktop} {
    height: 32px;
    padding: 0 32px;
    top: 32px;
  }
`;

export const FlexColumn = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const TicketContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;
`;

const TicketIcon = styled(SvgTicket)`
  color: #ffffff;
  width: 224px;
  height: 224px;
  transform: rotate(30deg);
  opacity: 0.08;
  position: absolute;
  right: -12%;
  top: calc(50% - 224px / 2 - 102px);
  ${({ theme }) => theme.mediaQueries.desktop} {
    width: 434px;
    height: 434px;
    right: -14%;
    top: calc(50% - 434px / 2 - 90px);
  }
`;

const Logo = styled(SetLiveKnockoutLogo)`
  width: 16.15vw;
  height: auto;
  ${({ theme }) => theme.mediaQueries.desktop} {
    width: 100px;
    height: 32px;
  }
`;

const MaskedContainer = styled.div`
  display: flex;
  flex-direction: column;
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' preserveAspectRatio='none meet' viewBox='0 0 374 410'%3E%3Cpath fill='%23000' d='M0 16C0 7.163 7.163 0 16 0h342c8.837 0 16 7.163 16 16v344.466c0 8.243-6.262 15.137-14.467 15.927l-342 32.919C8.134 410.217 0 402.828 0 393.386V16Z'/%3E%3C/svg%3E");
  mask-repeat: no-repeat;
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
`;

const GradientColor = styled.div`
  background: linear-gradient(
    47.52deg,
    #e71e3d 0.2%,
    rgba(231, 30, 61, 0.83) 19.05%,
    rgba(27, 0, 118, 0.73) 76.76%,
    #1b0076 96.25%
  );
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const LargeGradient = styled.div`
  background: linear-gradient(
    52.41deg,
    rgba(255, 255, 255, 0.03) 29.8%,
    rgba(255, 255, 255, 0.13) 67.19%
  );
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  clip-path: polygon(0 36.55%, 0% 100%, 113.6% 93.33%);
`;

const SmallGradient = styled.div`
  background: linear-gradient(320deg, #d9d9d90a 19%, #d9d9d975 65%);
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  clip-path: polygon(100% 65.26%, 76.47% 74.7%, 100% 86.5%);
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;


