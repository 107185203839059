import styled from "styled-components";
import { Body2, H2, Subtitle2 } from "melodies-source/Text";

interface SearchBoxProps {
  children?: React.ReactNode;
}

export const SearchBox = ({
  children,
}: SearchBoxProps) => {
  return (
    <Wrapper>
      <Content>
        <p style={{marginBottom:'15px', color: 'white'}}>Enter an artist's name to create a playlist from their Top Tracks!</p>
        {children}
        <Spacer />
      </Content>
    </Wrapper>
  );
};

const Spacer = styled.div`
  height: 100px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 56px 32px;
  position: relative;
  flex: 1 1 100%;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  z-index: 3;
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  ${H2}, ${Body2}, ${Subtitle2} {
    color: #ffffff;
    width: 100%;
    text-align: center;
  }
  ${H2} {
    font-weight: 700;
    text-transform: uppercase;
  }
  ${Body2} {
    margin-bottom: 16px;
    font-weight: 500;
  }
  ${Subtitle2} {
    opacity: 0.7;
    font-weight: 500;
    margin-top: 8px;
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    ${H2} {
      font-size: 34px;
      line-height: 50px;
    }
    ${Body2} {
      font-size: 15px;
      line-height: 22px;
    }
    ${Subtitle2} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 616px;
  height: min(109.67vw, 658px);
  flex-shrink: 0;
  padding: 8px;
  position: relative;
  ${({ theme }) => theme.mediaQueries.desktop} {
    height: 658px;
  }
`;
