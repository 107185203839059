import {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import { Button as ButtonBase } from "melodies-source/Button";
import { Checkbox } from 'melodies-source/Selectable';
import {
  AutocompleteAsync,
  Option as OptionType,
} from "melodies-source/Autocomplete";
import { SearchBox } from './Components';
import { DefaultLayout } from 'Layouts';
import { useSpotifyContext } from 'hooks';
import { SvgExternal } from 'Images/Icons';

export interface MatchedEventDetail {
  address: string;
  artistName: string;
  id: string;
  image: string;
  startsAt: number;
  venue: string;
  distance_meters?: number;
  geo_distance_meters?: number;
  isNearby?: boolean;
  version?: number;
}

export const Landing = () => {

  const { findArtist, authorize, token, user } = useSpotifyContext();

  const [tracks, setTracks] = useState<Record<string, any>[]>([]);
  const [selectedTracks, setSelectedTracks] = useState<string[]>([]);
  const [playlist, setPlaylist] = useState<Record<string, any>>();
  const [text, setText] = useState<string | null>(null);

  const artistRef = useRef<Record<string, any>>();
  const hideOptionsRef = useRef<boolean>(false);

  const placeholderText = 'Enter an artist...'

  const onEventSelectClickHandler = (artist: any) => {
    setText(artist.name);
    artistRef.current = artist;
    hideOptionsRef.current = true;
    getArtist(artist.id);

  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", "#000000");
  }, []);

  const getArtist = async (id: string) => {
    setTracks([]);
    const response = await fetch (
      `https://api.spotify.com/v1/artists/${id}/top-tracks?country=US`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    )
    if (!response.ok) {
      alert('Error: ' + response.statusText);
      return;
    }
    const json = await response.json();
    setTracks(json.tracks.map(track => ({ 
      id: track.id,
      uri: track.uri,
      name: track.name,
      url: track.external_urls.spotify,
    })))
  }

  const search = async (searchTerm: string) => {
    if (searchTerm && artistRef.current && artistRef.current.name === searchTerm) return;
    if (searchTerm?.length < 2) return;
    setPlaylist(null);
    setTracks([]);
    setSelectedTracks([]);
    setText(searchTerm);
    hideOptionsRef.current = false;
    return await findArtist(searchTerm) || [];
  };

  const createPlaylist = async () => {
    if (!artistRef.current || !selectedTracks?.length || !user) {
      if (!artistRef.current) {
        alert('No artist');
        return;
      }
      if (!selectedTracks.length) {
        alert('No tracks selected');
        return;
      }
      if (!user) {
        alert('No user!');
        return;
      }
    }
    let body: any = {
      name: `Set.live ${artistRef.current.name} Playlist`,
      description: `Top-tracks for ${artistRef.current.name} created on Set.live`,
      public: false,
    }
    let response = await fetch(
      `https://api.spotify.com/v1/users/${user.id}/playlists`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    )
    if (!response?.ok) {
      alert('Error: ' + response.statusText);
      return;
    }
    let json = await response.json();
    if (json?.error) {
      alert(json.error.message);
      return;
    }
    setPlaylist(json);
    body = {
      uris: selectedTracks,
    }
    response = await fetch (
      `https://api.spotify.com/v1/playlists/${json.id}/tracks`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    )
    if (!response?.ok) {
      alert(`Error: ${response.statusText}`);
      return;
    }
    json = await response.json();
    setTracks([]);
    setSelectedTracks([]);
  }

  return (
    <DefaultLayout>
        {!token && (
          <div>
            <div style={{
              zIndex: 1,
              color: 'white',
              marginBottom: '20px',
              marginTop:'20px',
              position: 'relative',
              textAlign: 'center',
            }}>
              <P>Sign-in to your Spotify&trade; account to create your ultimate Set.live&trade; playlist!</P>
            </div>
            <ButtonContainer>
                <Button onClick={authorize}>
                  <img src="/img/spotify-logo.png" alt="Spotify"/>Sign-In with Spotify&trade;
                </Button>
            </ButtonContainer>
          </div>
        )}
          {token && (
            <SearchContainer>
              <SearchBox>
                <Autocomplete
                  text={text!}
                  setText={setText}
                  getOptions={search}
                  placeholder={placeholderText}
                  hideDropdown={hideOptionsRef.current}
                  customOption={({ data }) => {
                    return (
                      <Item
                        key={data.id}
                        onClick={() => onEventSelectClickHandler(data)}
                      >
                        {(data?.images?.length > 0) && (
                         <P><img src={data.images[data.images.length-1].url} alt={data?.name} height='50px' width='50px'/></P>
                        )}
                        <P> {data?.name}</P>
                        <Footnote>
                          <span>
                            {data?.genres?.join(', ')}
                          </span>
                        </Footnote>
                        <Footnote>{data?.address}</Footnote>
                      </Item>
                    );
                  }}
                />
                <div style={{ padding: '10px', fontSize: '10px', width: '100%', display: 'flex', flexDirection: 'row' }}>
                  <div style={{ flexGrow: 1, textAlign: 'right', paddingRight: '5px', color: 'white', paddingTop: '4px' }}>
                    Powered by 
                  </div>
                  <div style={{ flexGrow: 0 }}>
                    <img src='/img/Spotify_Logo_RGB_White.png' height={20} style={{ verticalAlign: 'middle' }}/> 
                  </div>
                </div>
                {(!playlist && tracks?.length > 0) && (
                  <div style={{position:'relative', zIndex: 1, color: 'white'}}>
                    {tracks.map(track => (
                      <div style={{margin: '10px 0'}}>
                        <p>
                          <div style={{ display: 'flex', flexDirection:'row'}}>
                            <div>
                              <Checkbox 
                                value={selectedTracks?.includes(track.uri)} 
                                onChange={(selected) => {
                                  if (selected) {
                                    const t = Array.from(selectedTracks);
                                    t.push(track.uri);
                                    setSelectedTracks(t);
                                  } else {
                                    setSelectedTracks(selectedTracks.filter(t => t !== track.uri));
                                  }
                                }} 
                              />
                              </div>
                              <div style={{paddingLeft:'10px', display:'flex', flexDirection: 'column', justifyContent:'center'}}>
                                <strong>{track.name}</strong>
                              </div>
                              <div style={{paddingLeft:'5px', display:'flex', flexDirection: 'column', justifyContent:'center'}}>
                                <a href={track.url} target="_new"><SvgExternal/></a>
                              </div>
                            </div>
                          </p>
                      </div>
                    ))}
                    <div style={{ marginTop:'10px', textAlign: 'center'}}>
                      <Button disabled={!selectedTracks.length} onClick={createPlaylist}>Create Top Tracks Playlist</Button>
                    </div>
                  </div>
                )}
                {playlist && (
                  <div style={{marginTop:'30px', color: 'white'}}>
                    Your playlist has been created!
                    <p style={{marginTop:'20px', lineHeight:'1.2em'}}>
                      <a style={{color: '#fffff0'}} target="_new" href={playlist.external_urls.spotify}>Click here to listen</a>, or 
                      enter another artist to create a new playlist!
                    </p>
                  </div>
                )}
              </SearchBox>
            </SearchContainer>
          )}
    </DefaultLayout>
  );
}

const SearchContainer = styled.div`
    position: relative;
`;

const ButtonContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%
    color: white;
    img {
        width: 28px;
        margin-right: 10px;
    }
`;

const Button = styled(ButtonBase)`
  border-radius: 22px;
  background-color: #1DB954;
  color: #ffffff;
  border: 2px solid #ffffff;
  line-height: 24px;
  &:hover {
    background: #1dd667;
    color: #ffffff;
  }
`;

export const FlexColumn = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Footnote = styled.small`
  color: rgb(34, 34, 34);
  font-family: Poppins, sans-serif;
  font-size: 11px;
  line-height: 14px;
  font-weight: 300;
  font-style: italic;
`;
const Item = styled(FlexColumn)`
  background-color: "#FFFFFF";
  border-bottom: 1px solid ;
  cursor: pointer;
  position: relative;
  padding: 12px;
  p: {
    font-weight: 600;
  }
  ${Footnote} {
    color: #4c4c4c;
    font-style: normal;
    line-height: 16px;
  }
  ${Footnote} + ${Footnote} {
    color: #666666;
    font-style: italic;
    font-weight: 500;
  }
  span {
    font-weight: 600;
  }
  &:hover {
    background-color: initial;
  &:last-of-type {
    border-bottom: 0;
  }
`;

const Autocomplete = styled(AutocompleteAsync)`
  input {
    color: rgba(27, 0, 118, 0.91);
    font-size: 20px;
  }
  input::placeholder {
    font-size: 20px;
  }
`;

const P = styled.p``