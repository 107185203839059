import {createContext} from 'react';
import {initializeApp, getApps} from 'firebase/app';
import {getFunctions, httpsCallable} from 'firebase/functions';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const app = initializeApp(config);

const functions = getFunctions(app, 'us-central1');

export interface FirebaseContextProps {
  callable: (name: string, data?: Record<string, any>) => Promise<any>;
}

export const FirebaseContext = createContext<Partial<FirebaseContextProps>>({});

export const FirebaseProvider = ({children}:{ children: any }) => {

  const callable = async (name: string, data?: Record<string, any>): Promise<any> => {
    const func = httpsCallable(functions, name);
    try {
      return await func(data || {});
    } catch (error) {
      console.log(error);
      return {error};
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        callable,
      }}
    >
      {getApps().length > 0 ? (
        children
      ) : (
        <></>
      )}
    </FirebaseContext.Provider>
  );
};
