import {createContext, ReactNode, useState, useEffect} from 'react';

export interface SpotifyContextProps {
    token: string;
    user: Record<string, any>;
    findArtist: (name: string) => Promise<any>;
    authorize: () => void;
}

export const SpotifyContext = createContext<Partial<SpotifyContextProps>>({});

const tokenId = '__$__sp_token';
const tokenTimeout = 59 * 60 * 1000;

const CLIENT_ID = '6c6f80c6dd554fbb8bab74a47f78802d'; 
const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
const RESPONSE_TYPE = "token";
const scopes = [
    'playlist-modify-private', 
    'playlist-read-private', 
    'playlist-read-collaborative', 
    'playlist-modify-public',
    'user-follow-read',
    'user-library-read',
    'user-read-email',
];

export const SpotifyProvider = ({children}:{ children: ReactNode }) => {

    const [token, setToken] = useState<string>();
    const [user, setUser] = useState<Record<string, any>>();
    const [expiresAt, setExpiresAt] = useState<number>();

    useEffect(() => {

        let t = window.localStorage.getItem(tokenId);

        const decoded = JSON.parse(t);

        if (decoded?.token && new Date().getTime() < (decoded?.expires || 0)) {
            setToken(decoded.token);
            setExpiresAt(decoded.expires || 0);
            return;
        }

        const hash = window.location.hash;

        if (hash && (!decoded?.token || (decoded?.expires || new Date().getTime() -100) < new Date().getTime())) {
            const elems = hash.substring(1).split("&");

            if (elems?.length) {
                const element = elems.find(elem => elem.startsWith("access_token"));
                if (element) {
                    t = element.split("=")[1] || '';
                    window.location.hash = "";
                    // set token and expiration time of 59 minutes (tokens last 1 hour)
                    const expires = new Date().getTime() + tokenTimeout;
                    window.localStorage.setItem(tokenId, JSON.stringify({ token: t, expires }));
                    setToken(t)
                    setExpiresAt(expires);
                }
            }
        }

    }, []);

    useEffect(() => {
        (async () => {
            if (!token) return;
            const result = await fetch(
                'https://api.spotify.com/v1/me',
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            )
            if (result?.ok) {
                const json = await result.json();
                setUser(json);
            }
        })();
    },[token])
    const tokenValid = () => token; // && expiresAt && (expiresAt > new Date().getTime());

    const findArtist = async (name = '') => {
        if (!name?.trim()) return;
        if (!tokenValid() ) {
            console.log('Invalid token', token, expiresAt, new Date().getTime(), expiresAt > new Date().getTime());
            return;
        }
        const url = new URL('https://api.spotify.com/v1/search');
        url.search = new URLSearchParams({ type: 'artist', limit: '20', q: name }).toString();
        const response = await fetch (
            url.toString(),
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
        )
        if (!response.ok) {
            alert(`Error: ${response.statusText}`);
            return;
        }
        const json = await response.json();
        return json.artists?.items?.filter(item => item.name) || [];
    }

    const authorize = () => {
        window.location.href = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${window.location.href}&response_type=${RESPONSE_TYPE}&scope=${scopes.join(',')}`;
    }

    return (
    <SpotifyContext.Provider
      value={{
            token,
            user,
            findArtist,
            authorize,
      }}
    >
        {children}
    </SpotifyContext.Provider>
  );
};
